@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;800&display=swap');

@font-face {
  font-display: swap;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/ProductSans-Black.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/ProductSans-Bold-Italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/ProductSans-Bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/ProductSans-Medium.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/ProductSans-Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Product Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/ProductSans-Regular-Italic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/ProductSans-Light.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/ProductSans-Thin.woff2') format('woff2');
}
