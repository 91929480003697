// This class will impact to web version
.cdk-overlay-container {
    z-index: 1051 !important;
}

.cc-revoke {
    visibility: hidden !important;
    display: none !important;
}

.cc-window {
    visibility: hidden !important;
    display: none !important;
}

#mobile-app {
    font-family: 'Manrope', sans-serif;
    background-color: #121114;
    margin: auto;
    color: #ffffff;
    max-width: 500px;
    width: 100%;
    height: 100%;



    .form-setting {
        app-select {
            height: 52px !important;
            border-radius: 100px !important;
            padding: 8px;
            border: 1px solid #ffffff !important;

            .selected-option {
                border: none !important;
            }
        }


        .form-group.readonly {

            input:read-only,
            .input-group,
            .checkmark,
            .isChecked {
                color: rgba(255, 255, 255, 0.409) !important;
                border: 1px solid rgba(255, 255, 255, 0.409) !important;
                background-color: transparent !important;
            }
        }


        .btn-submit {
            border-radius: 100px;
            font-size: 14px;
            width: 182px;
            border: none;
            margin: auto;
            height: 46px !important;
            color: #ffffff;
            background-color: #8600F0;
            text-align: center;
        }

        .btn-submit:disabled {
            background-color: #313131;
        }

        .separator {
            margin: 10px 0px;
            border-bottom: 2px solid #1D1D1D;
            height: 2px;
        }

        app-date-field {
            .input-group {
                height: 52px !important;
                border: 1px solid #ffffff;
                padding: 8px;
                margin-bottom: 10px;
                border-radius: 100px !important;
            }
        }

        .options-container {
            background-color: #3b3a3c;
            margin-top: 19px;

            input[type="search"] {
                height: 44px;
                border-radius: 12px;
                padding: 0px 20px !important;
            }

            app-option {
                padding: 20px;
                height: 50px;
            }
        }

        app-geolocate-field {
            height: 52px !important;
            border-radius: 100px !important;
            margin-bottom: 15px;
            border-color: #ffffff;
            border-width: 1px !important;
            border-style: solid !important;
            padding: 8px;

            .input-group {
                border: none !important;
            }

            .autocomplete-container {
                top: calc(2.8em + 0.75rem + 2px);
            }
        }

        app-geolocate-field:focus {
            border-color: #9269F2 !important;
            box-shadow: none !important;
        }

        .btn-remove {
            background-color: #3F3F40;
            height: 52px;
            border-radius: 100px;
            width: 182px;
        }

        .btn-add {
            background-color: #8600F0;
            height: 52px;
            border-radius: 100px;
            width: 182px;
        }
    }

    .wrap-mobile-verfication {
        app-validation-indicator {
            width: 40px;
            height: 40px;
            margin: 5px 0px;

            .rounded-circle.valid {
                background-color: #8600F0 !important;
            }

            .rounded-circle {
                padding: 20px;
                margin-right: 20px;

                span {
                    width: 40px;
                    height: 40px;
                    position: relative;
                    bottom: 20px;
                    right: 6px;
                }
            }
        }
    }

    #data-result {
        z-index: 9999;
        background-color: #121114;
        width: 100%;
        height: 300px;
        overflow: scroll;

        .results {
            padding: 5px 20px;

            .result {
                padding: 10px 5px;
                display: flex;

                img {
                    width: 50px;
                    height: 50px;
                }

                border-bottom: 1px solid #454545;
            }
        }
    }

    #moreFilter {
        label {
            margin-bottom: 10px !important;
        }

        .bg-gray {
            background-color: transparent;
            padding: 0px !important;

            .form-control {
                margin-bottom: 0px;
            }
        }
    }

    input,
    select,
    textarea {
        color: #ffffff;
    }

    .input-euro {
        background-position: center;
        padding-left: 35px !important;
        background: url('/assets/img/mobile/currency-rp.svg') no-repeat scroll 15px center !important;
    }

    textarea:focus,
    input:focus {
        color: #ffffff;
    }

    select {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background: url('/assets/img/mobile/arrow-bottom.svg') no-repeat;
        background-position: calc(100% - 0.75rem) center !important;
    }

    .form-group {
        margin-top: 20px;

        label {
            font-weight: bold;
            font-family: 'Manrope', sans-serif;
            margin-bottom: 10px;
        }
    }

    .form-group-custom-inline {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .form-control-custom-sm {
        height: 29px;
        padding: 0px 5px;
        margin-right: 10px;
        width: auto;
        border-color: #ffffff !important;
        background-color: transparent !important;
    }

    .form-control-custom-sm:focus {
        color: #ffffff;
    }

    .btn-block {
        border: none;
    }

    .form-control-custom-sm::placeholder {
        font-family: 'Inter', sans-serif !important;
        color: #ffffff;
    }

    .custom-control {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
        margin-bottom: 8px !important;
    }

    .custom-control-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;
    }

    .custom-control-label {
        font-size: 16px;
        text-align: left;
    }

    .custom-control:focus~.custom-control-label::before {
        border-color: #9269F2 !important;
        box-shadow: none !important;
    }

    .custom-control:checked~.custom-control-label::before {
        border-color: #9269F2 !important;
        border-radius: 2px !important;
        background-color: #8600F0 !important;
        top: 0.25rem;
        left: -1.5rem;
    }

    .custom-control:focus:not(:checked)~.custom-control-label::before {
        border-color: #9269F2 !important;
    }

    .custom-control:focus:not(:checked)~.custom-control-label::before,
    .custom-control:not(:checked)~.custom-control-label::before,
    .custom-control:not(:disabled):active~.custom-control-label::before {
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        content: "";
        border: #9269F2 solid 1px;
        background-color: transparent !important;
        border-radius: 2px !important;
    }

    .custom-control-label::after {
        top: 0.25rem;
        left: -1.5rem;
    }

    .no-padding {
        padding: 0px;
    }

    .text-bold {
        font-weight: bold;
    }

    .form-control:focus {
        box-shadow: none;
    }

    .btn:hover {
        color: #ffffff;
    }

    .btn.focus,
    .btn:focus {
        box-shadow: none;
    }

    .link-view-all-slide {
        position: relative;
        top: 10px;
        padding: 0px 20px;
        width: auto;
        margin: auto 12px auto auto;
    }

    .link-view-all-slide a,
    .link-view-all-slide a:hover {
        color: #ffffff;
        text-decoration: none;
        margin: auto;
        font-size: 14px;
        vertical-align: middle;
        width: inherit;
    }

    .btn-transprent {
        background-color: transparent;
        color: #ffffff;
        text-align: center;
        border: none;
        height: 46px;
        font-size: 16px;
        width: auto;
        margin: 20px auto auto auto;
        border-radius: 100px;

        img {
            width: 18px;
            height: 18px;
            margin-right: 5px;
            position: relative;
            bottom: 0px;
        }
    }

    main {
        padding-bottom: 20px;
        min-height: 500px;

        .horizontal {
            overflow-x: auto;
            white-space: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;
            height: auto;
        }

        .horizontal::-webkit-scrollbar {
            display: none;
        }
    }

    .space {
        height: 140px;
        background-color: #121114;
    }

    .swiper-scrollbar {
        left: 0;
        right: 0;
        margin: auto !important;
        background-color: #454545;
        width: 100%;
        height: 2px;

        .swiper-scrollbar-drag {
            height: 4px;
            top: -1px !important;
            position: relative;
            width: 50% !important;
            background-color: #8600F0 !important;
        }
    }

    .btn-view-all {
        margin: auto -6px 28px auto !important;
        background-color: #8600F0;
        color: #ffffff;
        text-align: center;
        height: 46px;
        width: 182px;
        padding: 10px;
        border-radius: 100px;
    }

    .notif-badge {
        float: left;
        margin: auto 5px auto 0px;
        width: 8px;
        height: 8px;
        border-radius: 100%;
    }

    .notif-badge-danger {
        background-color: #FF007A;
    }

    .notif-badge-success {
        background-color: #05FF00;
    }

    .text-center {

        a,
        a:hover {
            color: #ffffff;
            text-decoration: none;
        }
    }

    .separator {
        margin: -15px 20px 10px 20px;
        border-bottom: 2px solid #1D1D1D;
        height: 2px;
    }

    .section-title {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: lighter;
        display: flex;

        .panel {
            height: 20px;
            background-color: transparent;
            border: none;
            margin: auto 0px auto auto;
        }

        p {
            margin: 0px;
        }
    }

    .textarea-control {
        height: 218px !important;
        border: 1px solid #ffffff;
        background-color: transparent;
        border-radius: 12px !important;
        width: 100%;
        padding: 10px;
    }

    .modal-dialog {
        position: absolute !important;
        left: 0px !important;
        right: 0px !important;
        margin: auto auto 0px auto !important;
        bottom: -100% !important;
        -webkit-transition: opacity 0.4s linear, bottom 0.3s ease !important;
        -moz-transition: opacity 0.4s linear, bottom 0.3s ease !important;
        -o-transition: opacity 0.4s linear, bottom 0.3s ease !important;
        transition: opacity 0.4s linear, bottom 0.3s ease !important;
    }

    .modal.bottom.fade.show .modal-dialog {
        bottom: 0 !important;
    }

    .modal {
        .wrap-alert {
            margin-bottom: 10px;
        }

        .btn-view {
            color: #ffffff;
            border-radius: 100px;
            height: 46px;
            width: 182px;
            padding: 13px;
            text-align: center;
            text-decoration: none;
            margin: auto;
            border: none;
            background-color: #8600F0;
        }

        .btn-submit {
            border-radius: 100px;
            font-size: 14px;
            width: 182px;
            border: none;
            margin: auto;
            height: 46px !important;
            color: #ffffff;
            background-color: #8600F0;
            text-align: center;
        }

        .btn-submit:disabled {
            background-color: #313131;
        }

        .modal-content {
            background-color: transparent !important;
            border-radius: 22px 22px 0px 0px !important;
            max-width: 400px !important;
            margin: auto !important;
            margin-bottom: 0px !important;

            .modal-background {
                z-index: -1;
                border-radius: 22px 22px 0px 0px;
                height: 100%;
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                -webkit-backdrop-filter: blur(50px);
                -moz-backdrop-filter: blur(50px);
                -ms-backdrop-filter: blur(50px);
                -o-backdrop-filter: blur(50px);
                backdrop-filter: blur(50px);
            }
        }

        .modal-header {
            padding: 5px 20px;

            .close:focus {
                outline: none;
            }

            .close {
                text-shadow: none !important;
                opacity: 100;

                img {
                    width: 13px;
                }
            }
        }

        .modal-body::-webkit-scrollbar {
            display: none;
        }

        .form-job {
            .ng-invalid {
                border-color: #ff0000 !important;
            }

            .text-info {
                margin-top: 12px;
                color: #ffffff !important;
            }

            .category {
                padding-top: 0px !important;
            }

            .countries {
                .btn-remove {
                    margin-bottom: 22px;
                }

                app-geolocate-field {
                    height: 52px !important;
                    border-radius: 100px !important;
                    margin-bottom: 15px;
                    border-color: #ffffff;
                    border-width: 1px !important;
                    border-style: solid !important;

                    .form-control {
                        border: none !important;
                        padding-top: 3px;
                    }
                }

                app-geolocate-field:focus {
                    border-color: #9269F2 !important;
                    box-shadow: none !important;
                }
            }

            .form-date {
                app-date-field.ng-pristine.mustCheck>div.input-group {
                    border-color: #ff0000;
                }

                .btn-remove {
                    margin-bottom: 22px;
                }

                .mat-datepicker-toggle {
                    padding-right: 1.5rem;
                }

                .wrap-input {
                    .times {
                        margin-top: 22px;
                    }
                }

                .mat-date-range-input-container {
                    height: 100%;
                }

                .input-group {
                    border-radius: 100px;
                    border: 1px solid #ffffff;
                    height: 52px;

                    .form-control {
                        height: 100%;
                        border: none;
                        margin-bottom: 0px;
                    }
                }

                .form-group-custom-inline {
                    display: flex;
                    margin-bottom: 10px;
                    flex-wrap: nowrap;
                    width: 100%;
                    margin-bottom: 22px;
                }
            }

            .filters {
                background: none !important;

                .btn-remove {
                    margin-bottom: 22px;
                }
            }

            .input-readonly {
                background-color: #3F3F40 !important;
                border: none !important;
            }

            .publishingChannels {
                .ng-invalid {
                    border: none !important;
                }

                .ng-invalid .checkmark {
                    border: 1px solid #ff0000;
                }
            }

            .mediaItems {
                .file-upload {
                    height: 218px !important;
                    width: 100%;
                }

                .media {
                    margin-bottom: 20px;
                    height: 218px !important;
                    width: 218px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;
                    border-radius: 10px !important;
                    border: 1px solid #ffffff;
                    position: relative;

                    .btn-delete-img {
                        top: 10px;
                        right: 10px;
                        border: none;
                        background-color: #000000;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        position: absolute;
                        padding: 0px;

                        img {
                            position: relative;
                            top: 3px;
                        }
                    }
                }
            }

            .form-control {
                height: 52px !important;
            }

            .custom-control:focus:not(:checked)~.custom-control-label::before,
            .custom-control:not(:checked)~.custom-control-label::before,
            .custom-control:not(:disabled):active~.custom-control-label::before {
                border-color: #ffffff;
            }

            .custom-control:not(:checked)~.ng-invalid::before {
                border-color: #ff0000;
            }

            .wrap {
                border-bottom: 2px solid #1D1D1D !important;
            }

            .selected-option {
                border-color: #ffffff;
                border-radius: 100px;
                height: 52px;
                margin-bottom: 20px;
            }

            .options-container {
                background-color: #3b3a3c;
                margin-top: 19px;

                input[type="search"] {
                    height: 44px;
                    border-radius: 12px;
                    padding: 0px 20px !important;
                }

                app-option {
                    padding: 20px;
                    height: 50px;
                }
            }

            .btn-remove {
                background-color: #3F3F40;
                height: 52px;
                border-radius: 100px;
                width: 182px;
            }

            .btn-add {
                background-color: #8600F0;
                height: 52px;
                border-radius: 100px;
                width: 182px;
            }

            .broadcastCountries {
                .selected-option {
                    margin-bottom: 0px !important;
                }

                app-select.ng-pristine.mustCheck>.selected-option {
                    border-color: #ff0000;
                }
            }

            .licenceCountries {
                .input-group {
                    border-radius: 100px;
                    border: 1px solid #ffffff;
                    height: 52px;

                    .form-control {
                        height: 100%;
                        border: none;
                        margin-bottom: 0px;
                    }
                }
            }


            .licenceDuration {
                .selected-option {
                    margin-bottom: 0px;
                }

                app-select.ng-pristine.mustCheck>.selected-option {
                    border-color: #ff0000;
                }

                input.form-control.ng-pristine.ng-untouched.ng-invalid {
                    border-color: #ff0000;
                    border-width: 1px !important;
                    border-radius: 100px !important;
                }

                app-select {
                    border: 1px #ffffff solid;
                    border-width: 1px !important;
                    border-radius: 100px;
                    height: 52px !important;

                    .selected-option {
                        border-radius: 100px;
                        height: 52px;
                        border: none !important;
                        margin-bottom: 20px;
                    }
                }
            }

            .haveTravelingCost {
                .ng-invalid {
                    border-radius: 0.25rem !important;
                }
            }

            .separator {
                margin: 15px 0px;
                border-bottom: 2px solid #1D1D1D;
                height: 2px;
            }

            .wrap-input {

                label {
                    font-size: 14px;
                    font-weight: bold;
                }

                input[type="time"]::-webkit-calendar-picker-indicator {
                    background: none;
                }

                margin-top: 22px;


                .endTime {
                    margin-left: 10px;
                }

                .startTime {
                    margin-right: 10px;
                }

                .times {
                    margin-top: 22px;
                }
            }

            label {
                margin-bottom: 10px;
            }
        }

        @media only screen and (min-height: 100px) and (max-height: 843px) {
            .modal-body {
                max-height: 400px !important;
                height: 100vh !important;
            }
        }

        @media only screen and (min-height: 844px) and (max-height: 1200px) {
            .modal-body {
                max-height: 600px !important;
                height: 100vh !important;
            }
        }

        .modal-body {
            padding: 0px 22px 20px 22px;
            height: inherit;
            overflow-x: auto;

            .preview-video {
                width: 325px;
                height: 253px;
                margin: auto;
                border: 2px solid #ffffff;
                border-radius: 6px;
                position: relative;

                button {
                    position: absolute;
                    right: 0px;
                    left: 0px;
                    top: 0px;
                    bottom: 0px;
                    border: 0px;
                    background-color: #000000;
                    border-radius: 100%;
                    padding: 10px;
                    width: 40px;
                    height: 40px;
                    margin: auto;
                    z-index: 99;
                }

                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 6px;
                }

                img {
                    border-radius: 6px;
                    width: 100%;
                    height: 100%;
                }
            }

            .upload-video {
                input {
                    display: none;
                }
            }

            .job-info {
                h6 {
                    font-size: 26px;
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 22px;
                }

                p {
                    text-align: center;
                }

                .contact {
                    background-color: #343434;
                    padding: 5px;
                    border-radius: 12px;

                    .wrap {
                        margin: auto;
                        display: flex;
                        border: none;
                        width: max-content;

                        img {
                            width: 73px;
                            height: 73px;
                        }

                        ol {
                            margin: 0px 0px 0px 10px;
                            padding: 2px;

                            li {
                                margin-bottom: 3px;
                                list-style: none;
                            }
                        }
                    }
                }
            }

            .request-tobook-info {
                h6 {
                    font-size: 26px;
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 22px;
                }

                ol {
                    text-align: left;

                    li {
                        margin-right: 5px;
                        display: inline-block;
                    }

                    li::before {
                        content: "\2022";
                        color: #ffffff;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                    }
                }
            }

            .travel-cost-info {
                h6 {
                    font-size: 26px;
                    font-weight: 500;
                    text-align: center;
                    margin-bottom: 22px;
                }

                p {
                    text-align: center;
                }

                .currency {
                    margin-right: 2px;
                }

                .item {
                    height: 89;
                    margin-bottom: 15px;
                    border-bottom: 2px solid #1D1D1D;
                    padding-bottom: 15px;

                    .title {
                        text-align: left !important;
                        margin-bottom: 0px;
                    }

                    ol {
                        padding: 0px;
                        margin: 0px;
                        list-style: none;
                        text-align: left;

                        li {
                            font-weight: bold;
                            margin-right: 5px;
                            display: inline-block;
                        }
                    }
                }
            }

            .cancel-booking-info {
                font-family: "Inter", sans-serif !important;

                h6 {
                    max-width: 240px;
                    margin: auto;
                    text-align: center;
                    font-weight: bold !important;
                }

                p {
                    font-weight: bold !important;
                }

                ol {
                    padding: 0px;
                    margin: 0px;
                    list-style: none;

                    li {
                        font-style: italic;
                        margin-bottom: 10px;
                    }
                }

                .talents {
                    max-height: 240px;
                    height: auto;
                    overflow-x: scroll;

                    button {
                        background-color: #000000;
                        border-radius: 100%;
                        width: 27px;
                        height: 27px;
                        border: none;
                        position: absolute;
                        right: 0px;

                        img {
                            width: 100% !important;
                            height: 100% !important;
                            margin: 0px !important;
                        }
                    }

                    .talent {
                        position: relative;
                        margin: 22px auto !important;
                        text-align: center;
                        width: 212px;
                        height: 80px;
                        border-bottom: 2px solid #1D1D1D;

                        .info {
                            display: inline-flex;
                            margin: auto;
                            width: 212px;

                            .profile {
                                width: 100px;
                            }
                        }

                        img {
                            width: 60px;
                            height: 60px;
                            margin-right: 10px;
                            border-radius: 100%;
                        }

                        p {
                            text-align: left;
                            margin-top: 17px;
                            font-weight: normal !important;
                            line-height: 3px;
                            font-size: 16px;
                        }

                        p:nth-child(1) {
                            font-weight: bold !important;
                        }

                        p:nth-child(2) {
                            font-size: 12px;
                        }
                    }
                }
            }

            .btn-bg-white {
                border: 1.1px solid #ffffff !important;
                padding-top: 5px;
                background-color: transparent !important;
            }

            .btn-bg-purple {
                padding-top: 5px;
                background-color: #8600F0 !important;
            }

            .btn-solid-white {
                padding-top: 5px;
                color: #8600F0 !important;
                background-color: #ffffff !important;
            }

            .btn-bg-gray {
                padding-top: 5px;
                background-color: #1D1D1D !important;
            }

            .btn-extra {
                border-radius: 100px;
                padding: 0px 0px 2px 0px;
                width: 40px;
                background-color: transparent;
                width: 183px;
                border: none;
                height: 46px;
                color: #ffffff;
                margin-top: 5px;
            }

            .terms {
                .custom-control-label {
                    font-size: 14px !important;

                    a {
                        display: inline;
                        font-size: 14px;
                    }
                }

                .custom-control:checked~.custom-control-label {
                    font-size: 16px;

                    a {
                        display: inline;
                        font-size: 16px;
                    }
                }
            }

            .signup-success {
                text-align: center;

                h6 {
                    font-size: 24px;
                    font-weight: 500;
                }

                p {
                    margin-top: 15px;
                }
            }

            .title {
                font-size: 18px;
                font-weight: 500px;
                margin-bottom: 20px;
            }

            .button-readmore {
                background-color: transparent;
                padding: 0px;
                border: none;
                text-decoration: none;
                color: #ffffff;
                font-size: 16px;
                margin-top: 5px;
            }

            .wrap {
                border-bottom: 1px solid #ffffff;
                padding: 20px 0px;
            }

            .form-control {
                height: 43px;
                margin-bottom: 20px;
                border: 1px solid #ffffff;
                border-radius: 100px !important;
                background-color: transparent;
                padding-left: 15px;
            }

            .form-group-custom-inline {
                margin-bottom: 10px;

                .form-control {
                    margin-bottom: 0 !important;
                }
            }

            .title-sign-up {
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 38px;
            }

            input::placeholder {
                text-indent: 0px;
                color: #ffffff;
            }

            .form-control:focus {
                border-color: #9269F2 !important;
                box-shadow: none !important;
            }

            a {
                display: block;
                color: #ffffff;
                font-size: 16px;
                text-decoration: none;
                margin-bottom: 20px;
            }

            app-geolocate-field {
                background: transparent;

                .autocomplete-container {
                    top: calc(2.5em + 0.75rem + 2px);
                    z-index: 2;
                }

                .input-group {
                    border: none;
                    border-radius: 100px;
                }
            }

            form {
                padding: 20px;
            }
        }

        .modal-header {
            border-bottom: none;

            button {
                background-color: #000000;
                border-radius: 100%;
                padding: 0px 0px 2px 0px;
                width: 40px;
                margin-top: 5px;
                height: 40px;
            }
        }

        .modal-footer {
            .wrap-btn {
                text-align: center;
                width: 100%;

                .btn {
                    width: 182px;
                }
            }

            .btn-bg-white {
                border: 1.1px solid #ffffff !important;
                padding-top: 5px;
                background-color: transparent !important;
            }

            .btn-bg-purple {
                padding-top: 5px;
                background-color: #8600F0 !important;
            }

            .btn-bg-gray {
                padding-top: 5px;
                background-color: #3F3F40 !important;
            }


            button {
                margin: auto;
                height: 40px;
            }

            .btn {
                margin-top: 10px;
                font-size: 16px;
                width: 100%;
                height: 42px;
                color: #ffffff;
                background-color: transparent;
                border: none;
            }

            border: none;
        }
    }
}